
interface IState {
  application: {
    authorise: boolean;
    user: any;
    redirectTo: any;
  };
  account: {
    userData: any;
  };
}

const InitialState: IState = {
  application: {
    authorise: false,
    user: null,
    redirectTo: null,
  },
  account: {
    userData: {},
  },
};
export default InitialState;
