export const URL_PATHS = {
  LOGIN: "/login",
  SIGNUP: "/signup",
  ACTIVE_ACCOUNT: "/activate",
  RESET_PASSWORD: "/reset-password",
  FORGOT_PASSWORD: "/forgot-password",
  NEW_PASSWORD: "/new-password",
  //ENROLMENT: '/enrolment',
  ACCOUNT: "/account",
  APP: "/",
  LMS: "https://course.playsafelysports.com",
  PLATFORM: "https://platform.playsafelysports.com",
};
