import React from "react";
import ReactDOM from "react-dom/client";
import "./styles/main.scss";
import { Auth } from "aws-amplify";
import {
  unstable_HistoryRouter as HistoryRouter,
  Route,
  Routes,
} from "react-router-dom";
import { createBrowserHistory } from "history";

import { Provider } from "react-redux";
import Store from "./store/store";
import Main from "./main";
import { MantineProvider } from "@mantine/core";
import { ModalsProvider } from "@mantine/modals";

import reportWebVitals from "./reportWebVitals";
import { awsConfig } from "./setting";

Auth.configure(awsConfig);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <Provider store={Store}>
      <MantineProvider
        theme={{
          colors: {
            brand: [
              "#eaf0fa",
              "#c0d2f1",
              "#96b5e8",
              "#6c97df",
              "#4279d6",
              "#2E308C", // primary
              "#173569",
              "#2455a8", // hover
              "#0e203f",
              "#050b15",
            ],
          },
          primaryColor: "brand",
        }}
      >
        <ModalsProvider>
          {/** @ts-ignore */}
          <HistoryRouter history={createBrowserHistory({ window })}>
            <Routes>
              <Route path="*" element={<Main />} />
            </Routes>
          </HistoryRouter>
        </ModalsProvider>
      </MantineProvider>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
