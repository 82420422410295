import AppReducer from "./reducer";
import InitialState from "./intial.state";
import { configureStore } from "@reduxjs/toolkit";

// import thunk from "redux-thunk";
/**
 * Initialise the application  store and reducers
 */
const store = configureStore({
  reducer: AppReducer,
  preloadedState: InitialState,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export default store;
