import Actions from "../consts";

export const application = (state = {}, action: any = {}) => {
  switch (action.type) {
    case Actions.AUTHORISE:
      return { ...state, authorise: true, user: action.payload };
    case Actions.UN_AUTHORISE:
      const tempSession = action.payload.tempSession;
      return { ...state, authorise: false, user: null, tempSession };
    case Actions.FORGOT_PASS:
      return { ...state, authorise: false, user: action.payload };
    case Actions.APP_LOADING:
      return { ...state, loading: action.payload };
    default:
      return state;
  }
};
