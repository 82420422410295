import React, { useEffect } from "react";
import { useSelector } from "react-redux";

import { Routes, Route, useNavigate } from "react-router-dom";
import ProtectedRoutes from "./components/routeController/app.route.controller";
import ActivateRouteController from "./components/routeController/activate.route.controller";
import App from "./pages/App";
import Login from "./pages/auth/login/login.page";
import SignUpPage from "./pages/auth/signup/signup.page";

import ProgressInfo from "./components/progressInfo/ProgressInfo";
import ActivateAccountPage from "./pages/auth/activate-account/activate.account.page";
import ResetPasswordPage from "./pages/auth/reset-password/reset.password.page";
import applicationComander from "./store/commanders/application.comander";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ForgotPasswordPage from "./pages/auth/forgot-password/forgot.password.page";
import NavbarComponent from "./components/navbar/navbar.component";
import { URL_PATHS } from "./consts/url.paths.consts";
import NavigatorHelper from "./helpers/navigator.helper";
import ChangePasswordPage from "./pages/auth/new-password/new-password";

const Main = () => {
  const navigate = useNavigate();
  NavigatorHelper.setNavigator(navigate);
  const user = useSelector((state: any) => state.account.userData);
  useEffect(() => {
    applicationComander.cmd_autoLogIn();
  }, []);

  return (
    <>
      <ToastContainer position="top-right" autoClose={1500} theme={"dark"} />
      <>
        <div id="routeContainer">
          <NavbarComponent user={user} />
          <Routes>
            <Route path={URL_PATHS.LOGIN} element={<Login />} />
            <Route
              path={URL_PATHS.ACTIVE_ACCOUNT}
              element={<ActivateRouteController />}
            >
              <Route index element={<ActivateAccountPage />}></Route>
            </Route>
            <Route path={URL_PATHS.SIGNUP} element={<SignUpPage />} />
            <Route
              path={URL_PATHS.FORGOT_PASSWORD}
              element={<ForgotPasswordPage />}
            ></Route>
            <Route
              path={URL_PATHS.NEW_PASSWORD}
              element={<ChangePasswordPage />}
            />
            <Route
              path={URL_PATHS.RESET_PASSWORD}
              element={<ResetPasswordPage />}
            ></Route>
            <Route path="*" element={<ProtectedRoutes />}>
              <Route path="*" element={<App />} />
            </Route>
          </Routes>
        </div>
      </>

      <ProgressInfo />
    </>
  );
};

export default Main;
