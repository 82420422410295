import { toast } from "react-toastify";
import { authFetch } from "../../helpers/authFetch";
import { HOST } from "../../setting";
import Const from "../consts";
import { URL_PATHS } from "../../consts/url.paths.consts";
import { progressAction } from "../helper/action.helper";
import axios from "axios";


export const ac_get_account = () => async (dispatch: any, getState: any) => {
  try {
    dispatch(ac_app_loading(true));
    const response: any = await authFetch(`${HOST}/api/account`, "get", null);
    dispatch({
      type: Const.GET_ACCOUNT,
      payload: response,
    });
    dispatch(ac_app_loading(false));
  } catch (e) {
    toast.error(e.message);
    dispatch(ac_app_loading(false));
  }
};

export const ac_update_account =
  (accountData, callback) => async (dispatch: any, getState: any) => {
    try {
      const response: any = await authFetch(
        `${HOST}/api/account`,
        "post",
        accountData
      );
      dispatch({
        type: Const.UPDATE_ACCOUNT,
        payload: response,
      });

      // if (response.enrolled) {
      //   dispatch({
      //     type: Const.REDIRECT,
      //     payload: { link: "/course" },
      //   });
      // }
      if (callback) callback();
      toast.info("Your Information Has Been Saved");
    } catch (e) {
      toast.error(JSON.parse(e.message.errorMessage).body);
    }
  };

export const ac_app_loading =
  (loading: boolean) => async (dispatch: any, getState: any) => {
    try {
      dispatch({
        type: Const.APP_LOADING,
        payload: loading,
      });
    } catch (e) {
      toast.error(e.message);
    }
  };

export const ac_get_email =
  (id_parent) => async (dispatch: any, getState: any) => {
    progressAction(async () => {
      try {
        const response: any = await axios.post(`${HOST}/api/account/email`, {
          id_parent,
        });
        dispatch({
          type: Const.GET_EMAIL,
          payload: response.data,
        });
      } catch (e) {
        toast.error(e.message);
      }
    });
  };
