import React, { useEffect, useState } from "react";
import applicationComander from "../../../store/commanders/application.comander";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSelector } from "react-redux";
import {
  faCircleUser,
  faLongArrowAltLeft,
} from "@fortawesome/free-solid-svg-icons";

import {
  Box,
  Button,
  Group,
  Space,
  Text,
  TextInput,
  Title,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { URL_PATHS } from "../../../consts/url.paths.consts";

const ActivateAccountPage = (props) => {
  const [activationCode, setActivationCode] = useState("");

  const userName = useSelector((state: any) => state.application.user.userName);
  const password = useSelector((state: any) => state.application.user.password);

  const onSubmit = (data) =>
    applicationComander.cmd_verify(userName, data.code, password);

  const form = useForm({
    initialValues: {
      code: "",
    },
  });

  // useEffect(() => {
  //     setUserName(props.location.state.userName);
  // }, [props.location.state.userName]);

  // useEffect(() => {
  //     setPassword(props.location.state.password);
  // }, [props.location.state.password]);

  return (
    <Box sx={{ maxWidth: 450 }} mx="auto" className="auth-container">
      <FontAwesomeIcon
        icon={faCircleUser}
        size={"5x"}
        className="auth-container--icon"
      />

      <Space h="xl" />
      <form
        onSubmit={form.onSubmit((values) => onSubmit(values))}
        className="u-w-100"
      >
        <Title order={4} align="center">
          Enter your confirmation code
        </Title>
        <Space h="md" />
        <TextInput
          required
          label="Code"
          placeholder=""
          className="u-w-100"
          {...form.getInputProps("code")}
        />
        <Text
          size="sm"
          align="right"
          className="auth-container--link"
          onClick={() => applicationComander.cmd_resend_code(userName)}
        >
          Resend Code
        </Text>

        <Group mt="lg" mb="sm">
          <Button size="lg" type="submit" className="u-w-100">
            Activate
          </Button>
        </Group>

        <Text
          size="sm"
          className="auth-container--link auth-container--link-darker"
          onClick={() =>
            applicationComander.cmd_redirect({ to: URL_PATHS.LOGIN })
          }
        >
          <FontAwesomeIcon icon={faLongArrowAltLeft} /> Back to login
        </Text>
      </form>
    </Box>
  );
};

export default ActivateAccountPage;
