export const APP_ROLES = {
  ADMIN: "admin",
  COACH: "coachsafely",
  ADMIN_LEAGUE: "admin_league",
  SALES_MAN: "salesman",
};

export const isValidRole = (
  userRoles: Array<{ id: string; value: string }> | null,
  requiredRoles: Array<string>
) => {
  if (
    !userRoles ||
    !userRoles.length ||
    !requiredRoles ||
    !requiredRoles.length
  )
    return false;
  return userRoles.some((role) => requiredRoles.includes(role.value));
};
