/* @ts-ignore */
import {
  ac_autoLogIn,
  ac_change_password,
  ac_forgot_password,
  ac_forgot_password_submit,
  ac_login,
  ac_resend_code,
  ac_signOut,
  ac_signup,
  ac_verify,
  ac_new_password
} from "../actions/auth.action";
import store from "../../store/store";
import Const from "../consts";
import NavigatorHelper from "../../helpers/navigator.helper";

const ApplicationComander = {
  cmd_signup: (userEmail, password, attr) => {
    // @ts-ignore
    store.dispatch(ac_signup(userEmail, password, attr));
  },
  cmd_login: (userEmail, password) => {
    // @ts-ignore
    store.dispatch(ac_login(userEmail, password));
  },
  cmd_autoLogIn: () => {
    // @ts-ignore
    store.dispatch(ac_autoLogIn());
  },
  cmd_signOut: () => {
    // @ts-ignore
    store.dispatch(ac_signOut());
  },
  cmd_verify: (userName: string, activationCode: string, password?: string) => {
    // @ts-ignore
    store.dispatch(ac_verify(userName, activationCode, password));
  },
  cmd_resend_code: (userName: string) => {
    // @ts-ignore
    store.dispatch(ac_resend_code(userName));
  },
  cmd_forgot_password: (userName: string) => {
    // @ts-ignore
    store.dispatch(ac_forgot_password(userName));
  },
  cmd_forgot_password_submit: (
    userName: string,
    resetCode: string,
    newPassword: string
  ) => {
    // @ts-ignore
    store.dispatch(ac_forgot_password_submit(userName, resetCode, newPassword));
  },
  cmd_redirect: (state: any) => {
    //@ts-ignore
    NavigatorHelper.navigate(state.to);
  },
  cmd_change_password: (oldPassword: string, newPassword: string) => {
    // @ts-ignore
    store.dispatch(ac_change_password(oldPassword, newPassword));
  },
  cmd_new_password: (newPassword: string, userSession: any) => {
    // @ts-ignore
    store.dispatch(ac_new_password(newPassword, userSession));
  },
};
export default ApplicationComander;
