import React, { Component } from "react";
import EventObservable from "../../services/observables/EventObservable";
import "./ProgressInfo.scss";
import {
  EVENT_HIDE_PROGRESS,
  EVENT_SHOW_PROGRESS,
} from "../../services/eventAction/progress.service";
import FullLoaderComponent from "../common/loader.component/full.loader.component";

class ProgressInfo extends Component<any, any> {
  constructor(props: any) {
    super(props);
    EventObservable.addEventListeners(EVENT_SHOW_PROGRESS, this);
    EventObservable.addEventListeners(EVENT_HIDE_PROGRESS, this);
    this.state = { visible: false, eventsCounter: 0 };
  }

  render() {
    return this.state.visible ? (
      <FullLoaderComponent loading={false} overlayLoading={true} />
    ) : null;
  }

  process = (event: any) => {
    event === EVENT_SHOW_PROGRESS
      ? this.setState((state, props) => {
          return { visible: true, eventsCounter: state.eventsCounter + 1 };
        })
      : this.setState((state, props) => {
          return { visible: state.eventsCounter > 1, eventsCounter: state.eventsCounter - 1 };
        });
  };
}

export default ProgressInfo;
