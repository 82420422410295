class NavigatorHelper {
  navigator: any;
  setNavigator = (navigator) => {
    this.navigator = navigator;
  };
  navigate = (to: string) => {
    this.navigator && this.navigator(to);
  };
}

const instance = new NavigatorHelper();
export default instance;
