const Actions = {
  AUTHORISE: "AUTHORISE",
  UN_AUTHORISE: "UN_AUTHORISE",
  FORGOT_PASS: "FORGOT_PASS",
  GET_ENROLMENT: "GET_ENROLMENT",
  EDIT_SELECTED_GROUP: "EDIT_SELECTED_GROUP",

  GET_ACCOUNT: "GET_ACCOUNT",
  UPDATE_ACCOUNT: "UPDATE_ACCOUNT",
  APP_LOADING: "APP_LOADING",
  GET_EMAIL: "GET_EMAIL"
};


export default Actions;
