import React from "react";
import "./navbar.component.scss";
import { Center, Grid, TextInput, Title, Button } from "@mantine/core";
import UserDiv from "../common/userdiv/UserDiv";
import { Link, useNavigate } from "react-router-dom";
import logo from "../../assets/logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBasketball } from "@fortawesome/free-solid-svg-icons";
import { Search } from "tabler-icons-react";
import { ArrowBack } from "tabler-icons-react";
import ApplicationComander from "../../store/commanders/application.comander";

const NavbarComponent = ({ user }: { user: any }) => {
  const navigate = useNavigate();
  return (
    <div className="co-nav">
      {/* <img
        alt="Rotation Icon"
        className="rotate-Logo"
        src={logo}
        draggable="false"
        height={40}
      /> */}

      <Grid align={"center"}>
        <Grid.Col span={4}>
          <div className="co-nav__brand">
            <Title order={1}>
              <a
                href="https://playsafelysports.com/"
                className="co-nav__brand--text"
              >
                <img
                  src={logo}
                  alt="playsports logo"
                  className="co-nav__brand--img"
                />
              </a>
            </Title>
          </div>
        </Grid.Col>
        <Grid.Col span={4}>
          {/* <TextInput
            radius={100}
            icon={<Search size={14} />}
            placeholder={"Search ..."}
          /> */}
        </Grid.Col>
        <Grid.Col span={4} style={{ justifyContent: "end", display: "flex" }}>
          {/* <Button
            leftIcon={<ArrowBack />}
            onClick={() => navigate(-1)}
            color="white"
            variant="light"
            radius="xl"
          >
            back
          </Button> */}

          {user && user.id ? (
            <Button
              leftIcon={<ArrowBack />}
              onClick={() => ApplicationComander.cmd_signOut()}
              color="white"
              variant="light"
              radius="xl"
            >
              Log out
            </Button>
          ) : null}
        </Grid.Col>
      </Grid>
    </div>
  );
};

export default NavbarComponent;
