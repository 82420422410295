import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
const PrivateRoutes = () => {
  const authorise = useSelector((state: any) => state.application.authorise);
  const activatedAccount = useSelector((state: any) =>
    state.application.user ? state.application.user.emailVerified : false
  );

  if (authorise) {
    return activatedAccount ? <Outlet /> : <Navigate to="/activate" />;
  } else {
    return <Navigate to="/login" />;
  }
};
export default PrivateRoutes;
