import React from "react";
import { Navigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import {useForm} from "react-hook-form";
import applicationComander from "../../../store/commanders/application.comander";
import { useSelector } from "react-redux";
import { faCircleUser } from "@fortawesome/free-solid-svg-icons";

import {
  Box,
  Button,
  Group,
  PasswordInput,
  Space,
  Text,
  TextInput,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { URL_PATHS } from "../../../consts/url.paths.consts";
import { emailValidator } from "../../../components/form/validators.form";

export default function LoginPage() {
  const appState = useSelector((state: any) => state.application);
  const onSubmit = (data) =>
    applicationComander.cmd_login(data.email.toLowerCase(), data.password);

  const form = useForm({
    initialValues: {
      email: "",
      password: "",
    },

    validate: {
      email: emailValidator,
    },
  });

  if (!appState.authorise) {
    return (
      <Box sx={{ maxWidth: 450 }} mx="auto" className="auth-container">
        <FontAwesomeIcon
          icon={faCircleUser}
          size={"5x"}
          className="auth-container--icon"
        />
        <Space h="xl" />
        <form
          onSubmit={form.onSubmit((values) => onSubmit(values))}
          className="u-w-100"
        >
          <TextInput
            required
            label="Email"
            placeholder="your@email.com"
            className="u-w-100"
            {...form.getInputProps("email")}
          />
          <Space h="md" />
          <PasswordInput
            required
            label="Password"
            placeholder="password"
            className="u-w-100"
            {...form.getInputProps("password")}
          />
          <Space h="xs" />

          <Text
            size="sm"
            align="right"
            className="auth-container--link"
            onClick={() =>
              applicationComander.cmd_redirect({
                to: URL_PATHS.FORGOT_PASSWORD,
                state: { email: form.values.email },
              })
            }
          >
            Forgot Password
          </Text>
          <Group mt="lg" mb="sm">
            <Button size="lg" type="submit" className="u-w-100">
              Login
            </Button>
          </Group>
          <Space h={"sm"}></Space>
          <Text
            size="sm"
            className="auth-container--link auth-container--link-darker"
            onClick={() =>
              applicationComander.cmd_redirect({ to: URL_PATHS.SIGNUP })
            }
          >
            Not Registered yet? <span className="u-color-primary">Sign Up</span>
          </Text>
        </form>
      </Box>
    );
  } else {
    return <Navigate to={URL_PATHS.APP} />;
  }
}
