import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
const ActivateRouteController = () => {
  const activatedAccount = useSelector((state: any) =>
    state.application.user ? state.application.user.emailVerified : null
  );
  return activatedAccount !== null && activatedAccount === false ? (
    <Outlet />
  ) : (
    <Navigate to="/" />
  );
};
export default ActivateRouteController;
