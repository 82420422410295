import {
  autoLogIn,
  changePassword,
  forgotPassword,
  forgotPasswordSubmit,
  resend_verification,
  signIn,
  signOut,
  signUp,
  verification,
  newPassword,
} from "../../services/auth/cognito.service";
import Const from "../consts";
import { toast } from "react-toastify";
import { WelcomeMessage } from "../helper/welcome.helper";
import { progressAction } from "../helper/action.helper";
import { ac_app_loading, ac_get_account } from "./account.action";
import { URL_PATHS } from "../../consts/url.paths.consts";
import NavigationHelper from "../../helpers/navigator.helper";

export const ac_login =
  (user: string, password: string) => async (dispatch: any, getState: any) => {
    try {
      const response: any = await signIn(user, password);

      if (
        response.challengeName &&
        response.challengeName === "NEW_PASSWORD_REQUIRED"
      ) {
        toast.warn("Please enter a new password!");
        NavigationHelper.navigate(URL_PATHS.NEW_PASSWORD);
        dispatch({
          type: Const.UN_AUTHORISE,
          payload: { tempSession: response },
        });

        return;
      }

      if (!response.attributes.email_verified) {
        throw new Error("User is not confirmed.");
      }

      dispatch({
        type: Const.AUTHORISE,
        payload: {
          userName: response.username,
          email: response.attributes.email,
          phoneNumber: response.attributes.phone_number,
          emailVerified: response.attributes.email_verified,
        },
      });
      dispatch(ac_get_account());
      toast.success(`${WelcomeMessage()} ${response.attributes.email}!`);
    } catch (e) {
      if (e.message.includes("User is not confirmed.")) {
        toast.warn("Please confirm your email!");
        resend_verification(user)
          .then((res) => {
            dispatch({
              type: Const.AUTHORISE,
              payload: {
                userName: user,
                password: password,
                phoneNumber: false,
                emailVerified: false,
              },
            });
          })
          .catch((e) => {
            toast.error(e.message);
          });

        return;
      }
      toast.error(e.message);
      dispatch({
        type: Const.UN_AUTHORISE,
        payload: {},
      });
    }
  };

export const ac_signup =
  (user, password, customAttr) => async (dispatch: any, getState: any) => {
    try {
      const response: any = await signUp(user, password, customAttr);
      dispatch({
        type: Const.AUTHORISE,
        payload: {
          userName: response.userName,
          password: password,
          email: response.email,
          phoneNumber: false,
          emailVerified: false,
        },
      });
      return response;
    } catch (e) {
      toast.error(e.message);
    }
  };

export const ac_verify =
  (userName: string, activationCode: string, password?: string) =>
  async (dispatch: any, getState: any) => {
    try {
      const response: any = await verification(userName, activationCode);
      toast("The account has been verified successfully");
      if (password) {
        dispatch(ac_login(userName, password));
      } else {
        NavigationHelper.navigate(URL_PATHS.LOGIN);
      }
      return response;
    } catch (e) {
      toast.error(e.message);
    }
  };

export const ac_resend_code =
  (userName) => async (dispatch: any, getState: any) => {
    try {
      const response: any = await resend_verification(userName);
      toast("Code has been resent to your email");
      return response;
    } catch (e) {
      toast.error(e.message);
    }
  };

export const ac_signOut = () => async (dispatch: any, getState: any) => {
  try {
    await signOut();
    dispatch({
      type: Const.UN_AUTHORISE,
      payload: {},
    });
  } catch (e) {
    toast.error(e.message);
  }
};

export const ac_autoLogIn = () => async (dispatch: any, getState: any) => {
  try {
    const userInfo: any = await autoLogIn();
    dispatch(ac_get_account());
    dispatch({
      type: !userInfo ? Const.UN_AUTHORISE : Const.AUTHORISE,
      payload: userInfo,
    });
    if (userInfo && userInfo.email) {
      toast.success(`${WelcomeMessage()} ${userInfo.email}!`);
    }
  } catch (e) {
    dispatch(ac_app_loading(false));
  }
};

export const ac_forgot_password =
  (userName: string) => async (dispatch: any, getState: any) => {
    try {
      await forgotPassword(userName ? userName.toLowerCase() : userName);
      toast(
        "A Security Code has been sent to your email, you will need this code in order to change your password"
      );

      dispatch({
        type: Const.FORGOT_PASS,
        payload: { userName: userName },
      });

      NavigationHelper.navigate(URL_PATHS.RESET_PASSWORD);
    } catch (e) {
      toast.error(e.message);
    }
  };

export const ac_forgot_password_submit =
  (userName: string, resetCode: string, newPassword: string) =>
  async (dispatch: any, getState: any) => {
    try {
      await forgotPasswordSubmit(
        userName ? userName.toLowerCase() : userName,
        resetCode,
        newPassword
      );
      toast("Password changed successfully");
      NavigationHelper.navigate(URL_PATHS.LOGIN);
    } catch (e) {
      toast.error(e.message);
    }
  };

export const ac_change_password =
  (oldPassword: string, newPassword: string) =>
  async (dispatch: any, getState: any) => {
    progressAction(async () => {
      try {
        const response: any = await changePassword(oldPassword, newPassword);
        toast("Password changed successfully");
        return response;
      } catch (e) {
        toast.error(e.message);
      }
    });
  };

export const ac_new_password =
  (password: string, userSession: any) =>
  async (dispatch: any, getState: any) => {
    progressAction(async () => {
      try {
        await newPassword(password, userSession);
        window.location.reload();
        toast("Password changed successfully");
      } catch (e) {
        toast.error(e.message);
      }
    });
  };
