import Actions from "../consts";

export const account = (state = { userData: {} }, action: any = {}) => {
  switch (action.type) {
    case Actions.GET_ACCOUNT:
      return { ...state, userData: { ...action.payload } };
    case Actions.UN_AUTHORISE:
      return { ...state, userData: {} };
    case Actions.UPDATE_ACCOUNT:
      return { ...state, userData: { ...action.payload } };
    default:
      return state;
  }
};
