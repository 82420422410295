// export const HOST =
//   process.env.NODE_ENV === "development"
//     ? "http://localhost:3001/dev"
//     : ""

const { REACT_APP_LOCAL, NODE_ENV } = process.env;

export const awsConfig_Dev = {
  identityPoolId: "us-east-1:f050906f-9acf-40ab-bff3-01acc4a4b888",
  region: "us-east-1",
  userPoolId: "us-east-1_OKR9HYJ5d",
  userPoolWebClientId: "qh7m0ask2mgn3do6a4vcqkcs8",
  cookieStorage: {
    domain: "localhost",
    secure: false,
    path: "/",
    expires: 365,
  },
};

// this is still dev
export const awsConfig_Prod = {
  identityPoolId: "us-east-1:599c183e-3541-4e65-973c-b88f56b4146a",
  region: "us-east-1",
  userPoolId: "us-east-1_5pPNDNyJd",
  userPoolWebClientId: "6ajajj74a26nd2rgsjkqthlvpc",
  authenticationFlowType: "USER_PASSWORD_AUTH",
  cookieStorage: {
    domain: ".playsafelysports.com",
    secure: true,
    path: "/",
    expires: 365,
  },
};

export const awsConfig =
  NODE_ENV == "development" ? awsConfig_Dev : awsConfig_Prod;

export const HOST =
  NODE_ENV == "development"
    ? REACT_APP_LOCAL && REACT_APP_LOCAL.trim() == "true"
      ? "https://u5p1ljz5ea.execute-api.us-east-1.amazonaws.com/dev"
      : "https://u5p1ljz5ea.execute-api.us-east-1.amazonaws.com/dev"
    : "https://nrdk9rl0d5.execute-api.us-east-1.amazonaws.com/prod";

export const ALLOW_IMGCACHE = false;
