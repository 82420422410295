export const emailValidator = (value) =>
  /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value)
    ? null
    : "Invalid email";

export const requiredValidator = (value) =>
  !value || (typeof value === "string" && value.trim() === "")
    ? "Required Field"
    : null;

export const validatePhone = (value) =>
  !value || !value.isValid ? "Invalid phone number" : null;
