import { useSelector } from "react-redux";
import FullLoaderComponent from "../components/common/loader.component/full.loader.component";
import { useState, useEffect } from "react";
import { Button, Container, createStyles, Group, Title } from "@mantine/core";
import { RootState } from "../store/store";
import { APP_ROLES, isValidRole } from "../helpers/roles.helper";
import { URL_PATHS } from "../consts/url.paths.consts";
import { openModal } from "@mantine/modals";
import { HeroText } from "../components/hero/hero.component";
export const useStyles = createStyles((theme) => ({
  pageWrapper: {
    padding: "3.5rem 2rem",
  },
}));

const App = () => {
  const loading = useSelector((state: any) => state.application.loading);
  const [isAdmin, setIsAdmin] = useState(false);
  const overlayLoading = useSelector(
    (state: any) => state.application.overlayLoading
  );

  const [redirecting, setRedirecting] = useState(false);

  const isInitialising = loading || overlayLoading;

  const renderLoading = () => {
    return (
      <FullLoaderComponent loading={loading} overlayLoading={overlayLoading} />
    );
  };

  const user = useSelector((state: RootState) => state.account.userData);
  const handlingUserValidation = (user) => {
    const isAdmin = isValidRole(user.roles, [
      APP_ROLES.ADMIN,
      APP_ROLES.ADMIN_LEAGUE,
    ]);
    if (!isAdmin) {
      setRedirecting(true);
      window.location.replace(URL_PATHS.LMS);
      return;
    }
    setIsAdmin(isAdmin);
  };

  useEffect(() => {
    if (user && user.id) {
      handlingUserValidation(user);
    }
  }, [user?.id]);

  return (
    <div className="app-container">
      {isInitialising || redirecting ? (
        renderLoading()
      ) : isAdmin ? (
        // <Container size={"md"} py={100}>
        //   <Title order={2}>Choose an app</Title>
        //   <Group position="center" mt={100}>
        //     <a className="link-text" href={URL_PATHS.PLATFORM}>
        //       <Button size="xl">Admin</Button>
        //     </a>
        //     <a className="link-text" href={URL_PATHS.LMS}>
        //       <Button size="xl">Course</Button>
        //     </a>
        //   </Group>
        // </Container>
        <HeroText />
      ) : null}
    </div>
  );
};

export default App;
